import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { app } from 'environments/app';

import { EnvService } from 'app/env.service';

const API_PREFIX = '/webapp/' + app.versionName();

@Injectable()
export class EndpointsService {
    private authService: any;
    private apiProtocol: string;
    private apiHostname: string;

    constructor(private env: EnvService) {
        this.apiProtocol = env.apiProtocol;
        this.apiHostname = env.apiHostname;
    }

    setAuthService(authService: any): void {
        this.authService = authService;
    }

    public get(
        key: string,
        params: any[] | null = null,
        domain?: string
    ) {
        const deviceDataServer = (domain ? this.apiProtocol + domain + API_PREFIX : null);

        let clientDataServer = this.apiProtocol + this.apiHostname + API_PREFIX;
        if (this.authService.clientDataServer) {
            clientDataServer = this.apiProtocol + this.authService.clientDataServer + API_PREFIX;
        }

        const endPoints = {
            'version': {
                'latest': this.apiHostname + '/api/version/latest/webapp',
                'minimumVersionAccepted': this.apiHostname + '/api/version/minimum/webapp',
            },
            'ipcamera': {
                'getVideoByDatetime': clientDataServer + '/ipcamera/getvideobydatetime',
                'getNextPrevVideo': clientDataServer + '/ipcamera/getnextprevvideo',
                'getImageByDatetime': clientDataServer + '/ipcamera/getimagebydatetime',
                'getNextPrevImage': clientDataServer + '/ipcamera/getnextprevimage'
            },
            'accounting': {
                'initpayment': clientDataServer + '/accounting/payment/init',
                'invoicelist': clientDataServer + '/accounting/invoices',
                'invoicedownload': clientDataServer + '/accounting/downloadinvoice',
                'invoiceStatus': clientDataServer + '/accounting/invoice/status',
                'recurencyStatus': clientDataServer + '/accounting/recurency/status',
                'regeneratePaymentUrl': clientDataServer + '/accounting/recurency/activate',
                'disableRecurency': clientDataServer + '/accounting/recurency/disable',
            },
            'auth': {
                'changePassword': clientDataServer + '/user/changepassword',
                'authLogin': clientDataServer + '/user/login',
                'authAutoLogin': clientDataServer + '/user/autologin',
                'setTokenExpiresAt': clientDataServer + '/user/settokenexpiresat',
                'verifyToken': clientDataServer + '/user/verifytoken',
                'minimumVersionAccepted': clientDataServer + '/user/minimumversionaccepted',
                'passwordRecovery': {
                    'sendemail': clientDataServer + '/user/passwordrecovery/sendemail',
                    'resetPassword': clientDataServer + '/user/passwordrecovery/resetpassword'
                },
            },
            'clientpartner': {
                'create': clientDataServer + '/clientpartner/createclientpartner',
                'list': clientDataServer + '/clientpartner/getclientpartnerslist',
                'update': clientDataServer + '/clientpartner/updateclientpartner',
                'delete': clientDataServer + '/clientpartner/deleteclientpartner',
                'viesVatChecker': clientDataServer + '/clientpartner/getclientpartnerVies'
            },
            'clientpartneraddress': {
                'list': clientDataServer + '/clientpartneraddress/list',
                'addEdit': clientDataServer + '/clientpartneraddress/addedit',
                'delete': clientDataServer + '/clientpartneraddress/delete',
            },
            'clientdevicegrup': {
                'list': clientDataServer + '/clientdevicegrup/list',
                'addEdit': clientDataServer + '/clientdevicegrup/addedit',
                'delete': clientDataServer + '/clientdevicegrup/delete',
                'attachdevice': clientDataServer + '/clientdevicegrup/attach',
                'detachdevice': clientDataServer + '/clientdevicegrup/detach',
            },
            'clientevents': {
                'geteventssetuplist': clientDataServer + '/events/geteventssetuplist',
                'addeventsetup': clientDataServer + '/events/addeventsetup',
                'delete': clientDataServer + '/events/deleteeventsetup',
                'changeEventStatus': clientDataServer + '/events/changeeventstatus'
            },
            'dispatcher': {
                'getUsers': clientDataServer + '/dispatcher/users',
                'getUserData': clientDataServer + '/user/userlistdata',
                'ruptelaTokenV2': clientDataServer + '/user/user/ruptela/token',
                'loginCts': environment.ctsLoginUrl,
                'ruptelaTt2': 'https://track2.cargotrack.ro/#/auth/token?token=',
            },
            'device': {
                'list': clientDataServer + '/device/list',
                'settings': {
                    'list': clientDataServer + '/settings/device/list',
                    'update': clientDataServer + '/settings/device/update'
                },
                'latestMileage': deviceDataServer + '/device/latestMileage',
                'latestPosition': deviceDataServer + '/device/latestPosition',
                'latestPositionRelative': deviceDataServer + '/device/latestPositionRelative',
                'positions': deviceDataServer + '/device/positions',
                'statusSince': deviceDataServer + '/device/statusSince',
                'fuelLevelSince': deviceDataServer + '/device/fuelLevelSince',
                'updateFuelConsumption': clientDataServer + '/device/update/fuelConsumption',
            },
            'devicecommand': {
                'getcommandstatus': clientDataServer + '/device/command/getcommandstatus',
                'sendcommand': clientDataServer + '/device/command/send',
                'getdevicecommandlogs': clientDataServer + '/device/command/getdevicecommandlogs',
            },
            'devicepositiontransmissionjob': {
                'get': deviceDataServer + '/device/positionTransmissionJob/get',
                'save': deviceDataServer + '/device/positionTransmissionJob/save',
                'deactivate': deviceDataServer + '/device/positionTransmissionJob/deactivate',
            },
            'driver': {
                'list': clientDataServer + '/driver/getclientdrivers',
                'save': clientDataServer + '/driver/saveclientdriver',
                'update': clientDataServer + '/driver/updateclientdriver',
                'delete': clientDataServer + '/driver/deletaclientdriver',
            },
            'document': {
                'getDocuments': clientDataServer + '/document/documentslist',
                'deleteDocuments': clientDataServer + '/document/deletedocuments',
                'saveDocument': clientDataServer + '/document/savedocument',
                'downloadDocument': clientDataServer + '/document/downloaddocument',
                'downloadDocuments': clientDataServer + '/document/downloaddocuments',
                'createCategory': clientDataServer + '/document/createcategory',
                'deleteCategory': clientDataServer + '/document/deletecategory',
                'editCategory': clientDataServer + '/document/editcategory',
            },
            'rer': {
                'getEmployees': clientDataServer + '/rer/employee/list',
                'addEmployee': clientDataServer + '/rer/employee/add',
                'updateEmployee': clientDataServer + '/rer/employee/edit',
                'deleteEmployee': clientDataServer + '/rer/employee/delete',
                'getIncidents': clientDataServer + '/rer/incident/list',
                'editIncident': clientDataServer + '/rer/incident/edit',
                'getUserClient': clientDataServer + '/rer/incident/userlist',
                'getRerClient': clientDataServer + '/rer/incident/clientlist',
                'getRerClientAddress': clientDataServer + '/rer/incident/clientaddresslist',
                'deleteIncident': clientDataServer + '/rer/incident/delete',
                'getIncidentLog': clientDataServer + '/rer/incident/incidentlog',
                'exportIncident': clientDataServer + '/rer/incident/exportincident',
                'getPlannings': clientDataServer + '/rer/planning/list',
                'getPlanningReadings': clientDataServer + '/rer/planning/readings',
                'updatePlanning': clientDataServer + '/rer/planning/edit',
                'deletePlanning': clientDataServer + '/rer/planning/delete',
                'getPlanningFromDate': clientDataServer + '/rer/planning/date',
                'copyPlannings': clientDataServer + '/rer/planning/copy',
                'exportPlannings': clientDataServer + '/rer/planning/export',
                'getPlanningLogs': clientDataServer + '/rer/planning/logs',
                'getSettings': clientDataServer + '/rer/settings/list',
                'updateSettings': clientDataServer + '/rer/settings/update',
                'getSettingsLogs': clientDataServer + '/rer/settings/logs',
                'getWasteTypeList': clientDataServer + '/rer/wastetype/list',
                'addEditWasteType': clientDataServer + '/rer/wastetype/addedit',
                'deleteWasteType': clientDataServer + '/rer/wastetype/delete',
                'getBinTypeList': clientDataServer + '/rer/bintype/list',
                'addEditBinType': clientDataServer + '/rer/bintype/addedit',
                'deleteBinType': clientDataServer + '/rer/bintype/delete',
                'addEditArea': clientDataServer + '/rer/area/addedit',
                'deleteArea': clientDataServer + '/rer/area/delete',
                'getAreaList': clientDataServer + '/rer/area/list',
                'getEmailTemplate': clientDataServer + '/rer/emailtemplate/list',
                'editEmailTemplate': clientDataServer + '/rer/emailtemplate/edit',
                'updateuserobjects': clientDataServer + '/rer/userbins/update',
                'updateuserbinsetupdocuments': clientDataServer + '/rer/userbinsetupdocuments/update',
                'importRerClientData': clientDataServer + '/rer/import/clients',
                'importRerTagData': clientDataServer + '/rer/import/tags',
                'importRerBinSetupData': clientDataServer + '/rer/import/binsetups',
                'exportRerData': clientDataServer + '/rer/export/associatedbins',
                'getFilterData': clientDataServer + '/rer/client/getfilterdata',
                'exportUserAssociatedObjectsList': clientDataServer + '/rer/export/associatedbins',
                'exportUserAssociatedRerBinSetupDocumentList': clientDataServer + '/rer/export/associatedsetupdocuments',
                'exportRerUnalocatedData': clientDataServer + '/rer/export/unallocatedobjects',
                'exportRerIlleditimateReadings': clientDataServer + '/rer/export/illegitimatereadings',
                'exportRerOrphanReadings': clientDataServer + '/rer/export/orphanreadings',
                'exportRerDeletedClientPartners': clientDataServer + '/rer/export/deletedclients',
                'exportRerDetailedReadings': clientDataServer + '/rer/export/detailedreadings',
                'exportRerBinSetups': clientDataServer + '/rer/export/binsetups',
                'exportRerInvoicingReadings': clientDataServer + '/rer/export/invoicingreadings',
                'exportRerCalendarInvoiceableReadings': clientDataServer + '/rer/export/calendarinvoiceablereadings',
                'exportRerDeviceCumulatedReading': clientDataServer + '/rer/export/devicecumulatedreadings',
                'exportLostAndSuspendedBins': clientDataServer + '/rer/export/lostandsuspendedbins',
                'getRerBinOwnershipList': clientDataServer + '/rer/binownership/list',
                'addEditRerBinOwnership': clientDataServer + '/rer/binownership/addedit',
                'deleteRerBinOwnership': clientDataServer + '/rer/binownership/delete',
                'exportRerTagHistory': clientDataServer + '/rer/export/taghistory',
                "collectionByWeight": {
                    'getAutomaticReportsList': clientDataServer + '/rer/collectionbyweight/automaticreport/list',
                    'automaticReportDownload': clientDataServer + '/rer/collectionbyweight/automaticreport/downloadreport',
                    "getClientSelectList": clientDataServer + '/rer/collectionbyweight/collectiondecision/clientselectlist',
                    "getDeviceSelectList": clientDataServer + '/rer/collectionbyweight/collectiondecision/deviceselectlist',
                    'exportRerCollectionByWeightClient': clientDataServer + '/rer/collectionbyweight/exportrercollectionbyweightclient',
                    'getRerCollectionClientAddressList': clientDataServer + '/rer/collectionbyweight/clientaddresslistcollection',
                    'exportRerCollectionByWeightInDetails': clientDataServer + '/rer/collectionbyweight/exportrercollectionbyweightindetail',
                    'getUnloadingZoneList': clientDataServer + '/rer/collectionbyweight/unloadingareas/list',
                    'addEditUnloadingArea': clientDataServer + '/rer/collectionbyweight/unloadingareas/addeditunloadingarea',
                    'activateOrDeactivateArea': clientDataServer + '/rer/collectionbyweight/unloadingareas/activateordeactivatearea',
                    'getWeighingReceiptList': clientDataServer + '/rer/collectionbyweight/weighingreceipt/list',
                    'editWeighingReceipt': clientDataServer + '/rer/collectionbyweight/weighingreceipt/editweighingreceipt',
                    'getWeighingReceiptLineList': clientDataServer + '/rer/collectionbyweight/weighingreceiptline/list',
                    "downloadProof": clientDataServer + '/rer/collectionbyweight/weighingreceipt/downloadproof',
                    'exportRerWeighingReceipts': clientDataServer + '/rer/collectionbyweight/exportrerweighigreceipts',
                },
                "smtptest": clientDataServer + '/rer/settings/smtptest',
            },
            'rerclient': {
                'addEdit': clientDataServer + '/rer/client/addedit',
                'delete': clientDataServer + '/rer/client/delete',
                'getFilteredList': clientDataServer + '/rer/client/getfilteredlist',
                'rfidTagReadings': clientDataServer + '/rer/client/rfidtagreadings',
                'recoverDeleted': clientDataServer + '/rer/client/recoverdeleted',
                'changeStatus': clientDataServer + '/rer/client/changestatus',
                'getChanges': clientDataServer + '/rer/client/getchanges',
                'listOfBins': clientDataServer + '/rer/client/getrerbinlist',
                'listOfRerBinSetupDocument': clientDataServer + '/rer/client/getrerbinsetupdocumentlist',
            },
            'rerbin': {
                'list': clientDataServer + '/rer/bin/list',
                'addEdit': clientDataServer + '/rer/bin/addedit',
                'delete': clientDataServer + '/rer/bin/delete',
                'unallocatedList': clientDataServer + '/rer/bin/unallocatedlist',
                'addEditUnallocated': clientDataServer + '/rer/bin/addeditunallocated',
                'changeobjectstatus': clientDataServer + '/rer/bin/changestatus',
                'sendBinSetupByEmail': clientDataServer + '/rer/bin/sendbinsetupbyemail',
                'downloadVerbalProcess': clientDataServer + '/rer/bin/download',
            },
            'rerbinsetupdocument': {
                'list': clientDataServer + '/rer/rerbinsetupdocument/list',
                'searchList': clientDataServer + '/rer/rerbinsetupdocument/search-list',
                'getRerBinSetupDocuments': clientDataServer + '/rer/rerbinsetupdocument/docList',
                'deleteBinSetupDocument': clientDataServer + '/rer/rerbinsetupdocument/delete',
            },
            'rerclientaddress': {
                'list': clientDataServer + '/rer/clientaddress/list',
                'addEdit': clientDataServer + '/rer/clientaddress/addedit',
                'delete': clientDataServer + '/rer/clientaddress/delete',
            },
            'rfidtag': {
                'listAvailable': clientDataServer + '/rer/rfidtag/list',
                'addEdit': clientDataServer + '/rer/rfidtag/addedit',
                'delete': clientDataServer + '/rer/rfidtag/delete',
                'allocations': clientDataServer + '/rer/rfidtag/allocationslogs'
            },
            'rerstreetgeozone': {
                'getList': clientDataServer + '/rer/city/list',
                'saveCity': clientDataServer + '/rer/city/save',
                'deleteCity': clientDataServer + '/rer/city/delete/',
                'deleteStreet': clientDataServer + '/rer/street/delete/',
                'saveStreet': clientDataServer + '/rer/street/save',
            },
            'rerroute': {
                'getList': clientDataServer + '/rer/route/list',
                'saveRoute': clientDataServer + '/rer/route/save',
                'deleteRoute': clientDataServer + '/rer/route/delete/',
                'getRerStreets': clientDataServer + '/rer/street/list/',
                'saveRerRouteStreets': clientDataServer + '/rer/route/save/street/',
                'getRoutePlanningSearchData': clientDataServer + '/rer/route/planning/search',
                'getRoutePlanningExecutionData': clientDataServer + '/rer/route/planning/execution/'
            },
            'agenda': {
                'getContacts': clientDataServer + '/agenda/contacts/list',
                'addContact': clientDataServer + '/agenda/contacts/add',
                'editContact': clientDataServer + '/agenda/contacts/edit',
                'deleteContact': clientDataServer + '/agenda/contacts/delete',
                'sendSms': clientDataServer + '/agenda/sms/send',
                'getSmsLog': clientDataServer + '/agenda/sms/log',
            },
            'geocode': {
                'searchGeocode': clientDataServer + '/geocode/search',
                'searchGoogleGeocode': clientDataServer + '/geocode/gsearch',
                'reverseGeocode': clientDataServer + '/geocode/reverse',
                'staticGeoMap': clientDataServer + '/proxy/google/staticmaps',
            },
            'geofence': {
                'saveGeofenceGroup': clientDataServer + '/geofence/addEditGeofenceGroup',
                'saveGeofence': clientDataServer + '/geofence/addEditGeofence',
                'deleteGeofence': clientDataServer + '/geofence/delete/geofence',
                'deleteGeofenceGroup': clientDataServer + '/geofence/delete/geofenceGroup',
                'getAll': clientDataServer + '/geofence/getAll',
            },
            'package': {
                'allData': clientDataServer + '/package/alldata',
                'addPackageAsDemo': clientDataServer + '/package/getindemo',
                'getPackageSupport': clientDataServer + '/package/getsupport',
            },
            'notification': {
                'list': clientDataServer + '/notification/list',
                'typelist': clientDataServer + '/notification/typelist',
                'unreadCount': clientDataServer + '/notification/getunreadnotificationscount',
                'markAsRead': clientDataServer + '/notification/read',
                'markAsUnread': clientDataServer + '/notification/setunreadusernotification',
                'save': clientDataServer + '/notification/saveusernotification',
                'delete': clientDataServer + '/notification/delete',
                'getNotificationType': clientDataServer + '/notification/getnotificationtype',
                'getNotificationPreference': clientDataServer + '/notification/getnotificationpreference',
                'updateNotificationPreference': clientDataServer + '/notification/updatenotificationpreference',
            },
            'user': {
                'savefrontendpreferences': clientDataServer + '/user/savefrontendpreferences',
                'ownuserrights': clientDataServer + '/user/ownuserrights',
                'isSuspended': clientDataServer + '/user/manage/issuspended',
                'getUserList': clientDataServer + '/user/manage/list',
                'getUserListWithObjects': clientDataServer + '/user/manage/listwithobjects',
                'getUserListWithSetupDocument': clientDataServer + '/user/manage/listwithsetupdocument',
                'getUserRightsList': clientDataServer + '/user/manage/rights',
                'addEditUser': clientDataServer + '/user/manage/addedit',
                'getUserLoginData': clientDataServer + '/user/manage/logininfo',
                'deleteUser': clientDataServer + '/user/manage/delete',
                'getUserRights': clientDataServer + '/user/manage/userrights',
                'saveUserRights': clientDataServer + '/user/manage/saverights',
                'getUserDeviceGroups': clientDataServer + '/user/manage/readDeviceGroups',
                'saveUserDevicesAndDeviceGroups': clientDataServer + '/user/manage/saveDeviceGroups',
                'useraccesslog': clientDataServer + '/user/useraccesslog',
                'usersettings': clientDataServer + '/user/usersettings'
            },
            'route': {
                'checkactiveshipping': clientDataServer + '/route/checkForActiveShipping',
                'lastdeliveryposition': clientDataServer + '/route/getvehiclelastdeliveryposition',
                'create': clientDataServer + '/route/create',
                'getvisibleroutes': clientDataServer + '/route/get/visibleroutes',
                'getdeviceroutes': clientDataServer + '/route/get/deviceroutes',
                'delete': clientDataServer + '/route/delete',
            },
            'report': {
                'getReportTypes': clientDataServer + '/report/types',
                'saveReport': clientDataServer + '/report/add',
                'getReports': clientDataServer + '/report/get',
                'getReportSumaryStatus': clientDataServer + '/report/get/summaryelementstatus',
                'getReportSummary': clientDataServer + '/report/summary',
                'deleteReport': clientDataServer + '/report/delete',
                'setStatusFlag': clientDataServer + '/report/retrysummaryelement',
                'updateDeviceRefuelingsData': deviceDataServer + '/device/update/refuelingthresholds',
                'getSummaryElements': deviceDataServer + '/report/summaryelements',
                'getDeviceDataRefuelings': deviceDataServer + '/report/data/refuelings',
                'getHistoryReport': deviceDataServer + '/report/data/history'
            },
            'tms': {
                'dashboard': {
                    'getwidgetvehicledata': clientDataServer + '/tms/getwidgetvehicledata',
                    'getwidgetshippingdata': clientDataServer + '/tms/getwidgetshippingdata',
                },
                'costs': {
                    'list': clientDataServer + '/tms/costs',
                    'addedit': clientDataServer + '/tms/costs/addeditcosts',
                    'delete': clientDataServer + '/tms/costs/delete',
                    'download': clientDataServer + '/tms/costs/download',
                },
                'shipping': {
                    'orderdocument': clientDataServer + '/tms/shipping/orderdocument',
                    'saveorderdocument': clientDataServer + '/tms/shipping/save/orderdocument',
                    'sendShippingSms': clientDataServer + '/tms/sendshippingsms',
                    'sendShippingEmail': clientDataServer + '/tms/sendshippingemail',
                    'sendInvoiceEmail': clientDataServer + '/tms/sendinvoiceemail',
                    'getShippings': clientDataServer + '/tms/shippings',
                    'getShippingById': clientDataServer + '/tms/shipping',
                    'getArchivedShippings': clientDataServer + '/tms/archivedShippings',
                    'getShippingStatusList': clientDataServer + '/tms/getshippingstatuslist',
                    'updateShippings': clientDataServer + '/tms/updateshipping',
                    'countShippingsForVehicle': clientDataServer + '/tms/countshippingsforvehicle',
                    'countShippingsForPartner': clientDataServer + '/tms/countshippingsforpartner',
                    'setInvoiceInit': clientDataServer + '/tms/setinvoiceinit',
                    'setInvoiceSent': clientDataServer + '/tms/setinvoicesent',
                    'setInvoiceStorno': clientDataServer + '/tms/setinvoicestorno',
                    'getVehicleLastDeliveryAddress': clientDataServer + '/tms/getvehiclelastdeliveryaddress',
                    'getRoute': clientDataServer + '/tms/route',
                    'getExportShippings': clientDataServer + '/tms/getshippingsforexport',
                    'invoice': {
                        'getInvoiceForShippings': clientDataServer + '/tms/shipping/invoice/invoices',
                        'getInvoice': clientDataServer + '/tms/shipping/invoice/getinvoice',
                        'getInvoiceLastDate': clientDataServer + '/tms/shipping/invoice/getlastinvoicedate',
                        'deleteInvoice': clientDataServer + '/tms/shipping/invoice/delete',
                        'saveInvoice': clientDataServer + '/tms/shipping/invoice/save',
                        'checkInvoiceSerialAndNumber': clientDataServer + '/tms/shipping/invoice/checkseriesandnumber',
                        'downloadInvoice': clientDataServer + '/tms/shipping/invoice/download',
                        'exchangeRate': clientDataServer + '/tms/shipping/invoice/getexchangerate',
                    },
                    'getSavedRoutes': clientDataServer + '/tms/get/savedroutes',
                    'document': {
                        'getDocuments': clientDataServer + '/tms/shippingdocumentslist',
                        'deleteDocument': clientDataServer + '/tms/shippingdeletedocument',
                        'saveDocument': clientDataServer + '/tms/shippingsavedocument',
                        'downloadDocument': clientDataServer + '/tms/shippingdownloaddocument',
                        'cloneDocument': clientDataServer + '/tms/shippingclonedocuments',
                    },
                    'getLogs': clientDataServer + '/tms/log/shippinglog',
                    'modification': {
                        'partner': clientDataServer + '/tms/shipping/modification/partner',
                        'referenceDate': clientDataServer + '/tms/shipping/modification/referencedate',
                        'referenceNo': clientDataServer + '/tms/shipping/modification/referenceno',
                        'weight': clientDataServer + '/tms/shipping/modification/weight',
                        'volume': clientDataServer + '/tms/shipping/modification/volume',
                        'specialConditions': clientDataServer + '/tms/shipping/modification/specialconditions',
                        'freightDescription': clientDataServer + '/tms/shipping/modification/freightdescription',
                        'transporter': clientDataServer + '/tms/shipping/modification/transporter',
                        'price': clientDataServer + '/tms/shipping/modification/price',
                        'companyDetails': clientDataServer + '/tms/shipping/modification/companydetails',
                        'transporterPrice': clientDataServer + '/tms/shipping/modification/transporterprice',
                        'invoiceSeries': clientDataServer + '/tms/shipping/modification/invoiceseries',
                        'invoiceNumber': clientDataServer + '/tms/shipping/modification/invoicenumber',
                        'invoiceDate': clientDataServer + '/tms/shipping/modification/invoicedate',
                        'invoiceExchangeRate': clientDataServer + '/tms/shipping/modification/invoiceexchangerate',
                        'invoiceTva': clientDataServer + '/tms/shipping/modification/invoicetva',
                        'invoicePaymentTerm': clientDataServer + '/tms/shipping/modification/invoicepaymentterm',
                        'invoiceText': clientDataServer + '/tms/shipping/modification/invoicetext',
                        'invoiceLanguage': clientDataServer + '/tms/shipping/modification/invoicelanguage'
                    }
                },
                'settings': {
                    'getclientsettings': clientDataServer + '/tms/settings/getclientsettings',
                    'updateclientsettings': clientDataServer + '/tms/settings/updateclientsettings',
                    'getnextdocumentnumber': clientDataServer + '/tms/settings/getnextdocumentnumber',
                    'getnextinvoicenumber': clientDataServer + '/tms/settings/getnextinvoicenumber',
                    'smtptest': clientDataServer + '/tms/settings/smtptest',
                    'incrementwizardstep': clientDataServer + '/tms/settings/incrementwizardstep',
                    'addvehiclesdriversfromwizard': clientDataServer + '/tms/settings/addvehiclesdriversfromwizard',
                    'addSelfClientPartner': clientDataServer + '/tms/settings/addselfclientpartner',
                    'getctsclientdata': clientDataServer + '/tms/settings/getctsclientdata'
                },
                'statstotalvalue': clientDataServer + '/tms/stats/totalvalue',
                'report': {
                    'getLogs': clientDataServer + '/tms/log/shippingslog'
                }
            },
            'tds': {
                'dashboard': {
                    'getwidgetvehicledata': clientDataServer + '/tds/getwidgetvehicledata',
                    'getwidgetshippingdata': clientDataServer + '/tds/getwidgetshippingdata',
                },
                'costs': {
                    'list': clientDataServer + '/tds/costs',
                    'addedit': clientDataServer + '/tds/costs/addeditcosts',
                    'delete': clientDataServer + '/tds/costs/delete',
                    'download': clientDataServer + '/tds/costs/download',
                },
                'goods': {
                    'list': clientDataServer + '/tds/goods',
                    'addedit': clientDataServer + '/tds/addeditgoodsitem',
                    'delete': clientDataServer + '/tds/deletegoodsitem',
                },
                'shipping': {
                    'orderdocument': clientDataServer + '/tds/shipping/orderdocument',
                    'saveorderdocument': clientDataServer + '/tds/shipping/save/orderdocument',
                    'sendShippingSms': clientDataServer + '/tds/sendshippingsms',
                    'sendShippingEmail': clientDataServer + '/tds/sendshippingemail',
                    'sendInvoiceEmail': clientDataServer + '/tds/sendinvoiceemail',
                    'getShippings': clientDataServer + '/tds/shippings',
                    'getShippingById': clientDataServer + '/tds/shipping',
                    'getArchivedShippings': clientDataServer + '/tds/archivedShippings',
                    'getShippingStatusList': clientDataServer + '/tds/getshippingstatuslist',
                    'updateShippings': clientDataServer + '/tds/updateshipping',
                    'countShippingsForVehicle': clientDataServer + '/tds/countshippingsforvehicle',
                    'countShippingsForPartner': clientDataServer + '/tds/countshippingsforpartner',
                    'setInvoiceInit': clientDataServer + '/tds/setinvoiceinit',
                    'setInvoiceSent': clientDataServer + '/tds/setinvoicesent',
                    'setInvoiceStorno': clientDataServer + '/tds/setinvoicestorno',
                    'getVehicleLastDeliveryAddress': clientDataServer + '/tds/getvehiclelastdeliveryaddress',
                    'getRoute': clientDataServer + '/tds/route',
                    'getExportShippings': clientDataServer + '/tds/getshippingsforexport',
                    'getEkaerExportShippings': clientDataServer + '/tds/getshippingsforekaerexport',
                    'getCargoExportShippings': clientDataServer + '/tds/getshippingsforcargoexport',
                    'invoice': {
                        'getInvoiceForShippings': clientDataServer + '/tds/shippinginvoices',
                        'getInvoice': clientDataServer + '/tds/shippinginvoice',
                        'getInvoiceLastDate': clientDataServer + '/tds/getlastinvoicedate',
                        'deleteInvoice': clientDataServer + '/tds/shippinginvoicedelete',
                        'saveInvoice': clientDataServer + '/tds/shippinginvoicesave',
                        'checkInvoiceSerialAndNumber': clientDataServer + '/tds/shippinginvoicecheckseriesandnumber',
                        'downloadInvoice': clientDataServer + '/tds/shippinginvoicedownload',
                    },
                    'getSavedRoutes': clientDataServer + '/tds/get/savedroutes',
                    'document': {
                        'getDocuments': clientDataServer + '/tds/shippingdocumentslist',
                        'deleteDocument': clientDataServer + '/tds/shippingdeletedocument',
                        'saveDocument': clientDataServer + '/tds/shippingsavedocument',
                        'downloadDocument': clientDataServer + '/tds/shippingdownloaddocument',
                        'cloneDocument': clientDataServer + '/tds/shippingclonedocuments',
                    }
                },
                'vehicle': {
                    'addEdit': clientDataServer + '/tds/vehicle/addeditvehicle',
                    'getVehicles': clientDataServer + '/tds/vehicle/getvehicles',
                    'delete': clientDataServer + '/tds/vehicle/deletevehicle',
                    'getVehicleMarks': clientDataServer + '/tds/vehicle/makes',
                    'getVehicleModels': clientDataServer + '/tds/vehicle/models',
                },
                'ekaer': {
                    'manageEkaerRequest': clientDataServer + '/tds/manageEkaerRequest',
                    'getEkaerCredentials': clientDataServer + '/tds/ekaer/credentials',
                    'getEkaerCredentialsByPartner': clientDataServer + '/tds/ekaer/credentialsByPartner',
                },
                'statstotalvalue': clientDataServer + '/tds/stats/totalvalue',
            },
            'clientdetail': {
                'tdsgetclientsettings': clientDataServer + '/tds/clientdetail/getclientsettings',
                'tdsupdateclientsettings': clientDataServer + '/tds/clientdetail/updateclientsettings',
                'tdsgetnextdocumentnumber': clientDataServer + '/tds/clientdetail/getnextdocumentnumber',
                'tdsgetnextinvoicenumber': clientDataServer + '/tds/clientdetail/getnextinvoicenumber',
                'tdssmtptest': clientDataServer + '/tds/clientdetail/smtptest'
            },
            'vehicle': {
                'addEdit': clientDataServer + '/tms/vehicle/addeditvehicle',
                'getVehicles': clientDataServer + '/tms/vehicle/getvehicles',
                'delete': clientDataServer + '/tms/vehicle/deletevehicle',
                'getVehicleMarks': clientDataServer + '/tms/vehicle/makes',
                'getVehicleModels': clientDataServer + '/tms/vehicle/models',
            },
            'tacho': {
                'gettachocredentials': clientDataServer + '/user/tachocredentials',
            },
            'sharelink': {
                'list': clientDataServer + '/sharelink/list',
                'add': clientDataServer + '/sharelink/add',
                'sendShareLink': clientDataServer + '/sharelink/sendShareLink',
                'remove': clientDataServer + '/sharelink/remove',
                'delete': clientDataServer + '/sharelink/delete',
                'checktoken': clientDataServer + '/sharelink/checktoken',
            },
            'roadtax': {
                'deviceList': clientDataServer + '/roadtax/device/list',
                'lastTransmission': deviceDataServer + '/roadtax/device/lastTransmission',
                'lastCross': deviceDataServer + '/roadtax/device/lastCross',
                'log': deviceDataServer + '/roadtax/log',
                'status': clientDataServer + '/roadtax/status',
                'huRequest': clientDataServer + '/roadtax/hu/request',
                'huBalance': clientDataServer + '/roadtax/hu/balance/info',
                'huBalanceLog': clientDataServer + '/roadtax/hu/balance/log',
                'downloadHuTollDocument': clientDataServer + '/roadtax/hu/download-toll',
                'downloadHuTollDocumentTotal': clientDataServer + '/roadtax/hu/download-toll-total',
                'huDeviceEdit': clientDataServer + '/roadtax/hu/device/edit',
                'plDeviceEdit': clientDataServer + '/roadtax/pl/device/edit',
                'plRechargeUrl': clientDataServer + '/roadtax/pl/portal',
                'huRechargeQuote': clientDataServer + '/roadtax/hu/recharge/quote',
                'huRechargeInit': clientDataServer + '/roadtax/hu/recharge/init',
                'bgBalance': clientDataServer + '/roadtax/bg/balance/info',
                'bgBalanceLog': clientDataServer + '/roadtax/bg/balance/log',
                'bgDeviceEdit': clientDataServer + '/roadtax/bg/device/edit',
                'bgDeviceLog': clientDataServer + '/roadtax/bg/device/log',
                'huDeviceLog': clientDataServer + '/roadtax/hu/device/log',
                'bgRechargeQuote': clientDataServer + '/roadtax/bg/recharge/quote',
                'bgRechargeInit': clientDataServer + '/roadtax/bg/recharge/init',
                'updateRoadtaxHuLowTresholdAndDefaultRecharge': clientDataServer + '/roadtax/hu/update-balance-treshold-and-recharge-amount',
                'carGOboxRechargeQuote': clientDataServer + '/roadtax/cargobox/recharge/quote',
                'carGOboxRechargeInit': clientDataServer + '/roadtax/cargobox/recharge/init',
                'carGOboxBalance': clientDataServer + '/roadtax/cargobox/balance/info',
                'getT4eInvoices': clientDataServer + '/roadtax/cargobox/t4einvoices',
                'downloadT4eInvoice': clientDataServer + '/roadtax/cargobox/t4einvoices/download/',
                'carGOboxTransactions': clientDataServer + '/roadtax/cargobox/transactions',
                'carGOboxExportTransactions': clientDataServer + '/roadtax/cargobox/transactions/exportcsv',
                'cargoboxInvoicesComputeCPCBTotal': clientDataServer + '/roadtax/cargobox/invoices/compute-cpcb-total',
                'getDocumentLinesBySummary': clientDataServer + '/roadtax/cargobox/get-document-lines-by-summary',
                'getTollEstimations': clientDataServer + '/roadtax/cargobox/get-toll-estimation',
                'huInvoiceCheckFinalUnpaid': clientDataServer + '/roadtax/hu/invoices/check-final-unpaid',
            },
            'pgs': {
                'client': {
                    'list': clientDataServer + '/pgs/client/list',
                    'addEdit': clientDataServer + '/pgs/client/addeditclient',
                    'delete': clientDataServer + '/pgs/client/delete',
                    'addEditClientAddress': clientDataServer + '/pgs/client/addeditclientaddress',
                    'deleteClientAddress': clientDataServer + '/pgs/client/deleteclientaddress',
                    'getSentReviews': clientDataServer + '/pgs/client/sentreview',
                    'getReceivedReviews': clientDataServer + '/pgs/client/receivedreview'
                },
                'driver': {
                    'list': clientDataServer + '/pgs/driver/list',
                    'addEdit': clientDataServer + '/pgs/driver/addeditdriver',
                    'delete': clientDataServer + '/pgs/driver/delete',
                    'getReceivedReviews': clientDataServer + '/pgs/driver/receivedreview',
                    'getSentReviewsClient': clientDataServer + '/pgs/driver/sentreviewclient',
                    'getSentReviewsLoadingTeam': clientDataServer + '/pgs/driver/sentreviewloadingteam',
                },
                'vehicle': {
                    'list': clientDataServer + '/pgs/vehicle/list',
                    'addEdit': clientDataServer + '/pgs/vehicle/addeditvehicle',
                    'delete': clientDataServer + '/pgs/vehicle/delete',
                    'getReceivedReviews': clientDataServer + '/pgs/vehicle/receivedreview',
                    'checkProvider': clientDataServer + '/pgs/vehicle/checkprovider',
                },
                'transporter': {
                    'list': clientDataServer + '/pgs/transporter/list',
                    'addEdit': clientDataServer + '/pgs/transporter/addedittransporter',
                    'delete': clientDataServer + '/pgs/transporter/delete',
                    'driverlist': clientDataServer + '/pgs/transporter/listdriver',
                    'vehiclelist': clientDataServer + '/pgs/transporter/listvehicle',
                    'getReceivedReviews': clientDataServer + '/pgs/transporter/receivedreview',
                    'getSentReviewsClient': clientDataServer + '/pgs/transporter/sentreviewclient',
                    'getSentReviewsLoadingTeam': clientDataServer + '/pgs/transporter/sentreviewloadingteam',
                },
                'delivery': {
                    'list': clientDataServer + '/pgs/delivery/list',
                    'delete': clientDataServer + '/pgs/delivery/delete',
                    'statusUpdate': clientDataServer + '/pgs/delivery/updatestatus',
                    'getReviews': clientDataServer + '/pgs/delivery/reviewlist',
                    'getDeliveryStops': clientDataServer + '/pgs/delivery/stops',
                    'getDeliveryItems': clientDataServer + '/pgs/delivery/items',
                    'excellDeliveriesList': clientDataServer + '/pgs/delivery/excelldeliverieslist',
                    'sendSmsToDriver': clientDataServer + '/pgs/delivery/sendsmstodriver'
                },
                'settings': {
                    'getSettings': clientDataServer + '/pgs/settings/getsettings',
                    'updateSettings': clientDataServer + '/pgs/settings/updatesettings'
                },
                'dashboard': {
                    'deliveryList': clientDataServer + '/pgs/dashboard/deliverylist',
                    'transporterReviews': clientDataServer + '/pgs/dashboard/transporterreviews',
                    'clientReviews': clientDataServer + '/pgs/dashboard/clientreviews',
                    'loadingTeamReviews': clientDataServer + '/pgs/dashboard/loadingteamreviews',
                    'graphDeliveries': clientDataServer + '/pgs/dashboard/deliveriesgraph'
                },
            },
            'e-transport': {
                'getActiveUitList': clientDataServer + '/e-transport/uit/active-list',
                'getHistoryUitList': clientDataServer + '/e-transport/uit/history-list',
                'refreshUitList': clientDataServer + '/e-transport/refresh-uit-list',
                'updateLastSentPositionAndCountry': clientDataServer + '/e-transport/uit/get-last-sent-position-and-country',
                'getDeviceList': clientDataServer + '/e-transport/device/list',
                'saveUitScheduler': clientDataServer + '/e-transport/uit/edit-scheduler',
                'saveUitDeviceDeclaration': clientDataServer + '/e-transport/uit/declaration/save',
                'cancelUitDeviceDeclaration': clientDataServer + '/e-transport/uit/declaration/cancel',
                'getlUitDeviceDeclaration': clientDataServer + '/e-transport/uit/declaration/get',
                'cancelUitScheduler': clientDataServer + '/e-transport/uit/cancel-scheduler',
                'stopUitScheduler': clientDataServer + '/e-transport/uit/stop-transmission',
                'addUit': clientDataServer + '/e-transport/uit/add',
                'saveUit': clientDataServer + '/e-transport/uit/save',
                'getUitDetails': clientDataServer + '/e-transport/uit/details',
                'getDeviceDeclarationDetails': clientDataServer + '/e-transport/uit/declaration',
                'getUITPositionList': clientDataServer + '/e-transport/uit/positions-list/',
                'exportUitSentPositions': clientDataServer + '/e-transport/uit/export-positions-list/',
                'getSchedulerHistory': clientDataServer + '/e-transport/uit/scheduler-logs',
                'viewJwtToken': clientDataServer + '/e-transport/jwt/view',
                'createJwtToken': clientDataServer + '/e-transport/jwt/create',
                'deleteJwtToken': clientDataServer + '/e-transport/jwt/delete',
                'getIsAutoCreateScheduler': clientDataServer + '/e-transport/get-contract-preferences',
                'updateContractPreferences': clientDataServer + '/e-transport/set-contract-preferences',
                'getSchedulerMinimumDuration': clientDataServer + '/e-transport/get-scheduler-minimum-duration',
                'getMaxAllowedPastHours': clientDataServer + '/e-transport/get-max-allowed-past-hours',
            },
            'util': {
                'myIp': clientDataServer + '/util/myIp',
            },
        };

        let qString = this.resolvePath(key, endPoints);

        if (params && params.length) {
            qString += '/' + params.join('/');
        }
        if (qString && qString.search(/^https?:\/\//) === -1) {
            qString = this.apiProtocol + qString;
        }
        return qString;
    }

    private resolvePath(stringPath, baseObject): any {
        return stringPath.split('.').reduce(
            (p, q) => {
                return p ? p[q] : null;
            }, baseObject || self);
    }
}
