import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../service/auth.service';

import { Device } from '../../model/settings/device.model';
import { EndpointsService } from 'app/service/endpoints.service';
import { NotificationType } from 'app/modules/model/settings/notificationtype.model';
import { NotificationPreference } from 'app/modules/model/settings/notificationpreference.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    
    private clientJwtTokenSubject = new BehaviorSubject(null);
    public clientJwtToken$ = this.clientJwtTokenSubject.asObservable();

    public minimumVersion: String;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private translateService: TranslateService,
        private endpointsService: EndpointsService
    ) { }

    public getDeviceList(): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.get(this.endpointsService.get('device.settings.list'));
    }

    public updateDevice(device: Device): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.post(this.endpointsService.get('device.settings.update'), device);
    }

    public getLatestVersion(): Observable<any> {
        return this.httpClient.get(
            this.endpointsService.get('version.latest'));
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    public getLatestMileage(deviceId: number, hostname: string): Observable<{ devicetime: string, mileage: number }> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }
        return this.httpClient.post<{ devicetime: string, mileage: number }>(
            this.endpointsService.get('device.latestMileage', [deviceId], hostname), {}
        );
    }

    public getNotificationType(): Observable<NotificationType[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('notification.getNotificationType'))
            .pipe(map(
                (notificationTypes: any[]) => {
                    const notificationTypeList: NotificationType[] = [];
                    notificationTypes.forEach(
                        notificationType => {
                            notificationTypeList.push(
                                new NotificationType(
                                    notificationType.id,
                                    notificationType.name,
                                    notificationType.class
                                )
                            );
                        }
                    );
                    return notificationTypeList;
                }
            ))
    }

    public getNotificationPreference(): Observable<NotificationPreference[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('notification.getNotificationPreference'))
            .pipe(map(
                (notificationPreferences: any[]) => {
                    const notificationPreferenceList: NotificationPreference[] =[];
                    notificationPreferences.forEach(
                        notificationPreference => {
                            notificationPreferenceList.push(
                                new NotificationPreference(
                                    notificationPreference.user_notification_type_id,
                                    notificationPreference.channel,
                                )
                            );
                        }
                    );
                    return notificationPreferenceList;
                }
            ))
    }

    public updateNotificationPreference(notificationPreferenceList: NotificationPreference[]): Observable<any[]> {
        return this.httpClient.post<any[]>(this.endpointsService.get('notification.updateNotificationPreference'), notificationPreferenceList);
    }

    public getMinimumVersion(): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('version.minimumVersionAccepted'));
    }

    public viewEtransportJwtToken(): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('e-transport.viewJwtToken')).pipe(map((data: { jwt: any }) => this.clientJwtTokenSubject.next({ jwt: data.jwt})));
    }

    public createEtransportJwtToken(token: string, refreshToken: string): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('e-transport.createJwtToken'), { token, refreshToken }).pipe(
            map((data: { jwt: any, registrationUrl: string }) => this.clientJwtTokenSubject.next({ jwt: data.jwt} ))
        );
    }

    public generateEtransportJwtToken(hostname: string): Observable<any> {
        return this.httpClient.get(hostname + 'remote-auth/get-redirect-url').pipe(map((data: { redirectUrl: string }) => data.redirectUrl));
    }

    public checkAnafAuthorizationCode(hostname: string, params: {code: string, userId: string, token: string, dsId: number}): Observable<any> {
        return this.httpClient.post(hostname + 'remote-auth/obtain-jwt-token', params).pipe(map((data: { redirectUrl: string }) => data.redirectUrl));
    }

    public deleteEtransportJwtToken(): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('e-transport.deleteJwtToken')).pipe(map((data: { jwt: any }) => this.clientJwtTokenSubject.next({ jwt: null })));
    }
}
