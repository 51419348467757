import { NotificationTypeInterface } from '../../settings/interface/notificationtype.interface';

export class NotificationType implements NotificationTypeInterface {

    static E_TRANSPORT: number = 16;

    constructor(
        public id = null,
        public name = null,
        public icon = null,
    ) { }

}
